import React from "react"
import AboutSection from "../about/aboutSection"
import KytsTeam from "../about/kytsTeam"
import HomeSlider from "./homeSlider"
import EventsList from "../events/events"
import SponsorsList from "../about/sponsors"

const HomePage = () => {
  return (
    <>
      <HomeSlider />
      {/* Start About Area */}
      <AboutSection />
      {/* End About Area */}
      {/* Start Events Area */}
      <section className="event-section pt-70 pb-70">
        <div className="container">
          <div className="section-title">
            <span>
              <i className="flaticon-clipboard" />
              Upcoming Events
            </span>
            <h2>Be ready for Our Events</h2>
            <p>
              We seek to promote and co-ordinate Tamil culture, educational and community activities. We are open to all persons interested in promoting the Tamil language and culture.
            </p>
          </div>
          <EventsList />
        </div>
      </section>
      {/* End Events Area */}
      <KytsTeam />
      <SponsorsList />
      {/* End Team Area */}
    </>
  )
}

export default HomePage

import React from "react"
import Carousel from "react-bootstrap/Carousel"
import UseHttpRequest from "../../hooks/HttpRequestSlider"

const HomeSlider = () => {
  const allData = UseHttpRequest()
  if (allData.length <= 0) {
    return (
      <Carousel>
        <Carousel.Item>
          <img
            className="gallery-image"
            src="https://demo.kentuckytamilsangam.org/uploads/banner_Mamallapuram_132514938000000000.jpg"
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    )
  }
  const data = allData.Banners
  return (
    <Carousel>
      {data.map((item, index) => (

        <Carousel.Item key={index}>
          {
            item.EventSlug.length === 0 ?
              <>
                <img
                  className="gallery-image"
                  src={item.ImageURL}
                  alt="Third slide"
                />

                <Carousel.Caption>
                  <h1>{item.ImageTitle}</h1>
                  <p>{item.Description}</p>
                </Carousel.Caption>
              </>
              :
              <>
                <a href={"/event/" + item.EventSlug}>
                  <img
                    className="gallery-image"
                    src={item.ImageURL}
                    alt="Third slide"
                  />

                  <Carousel.Caption>
                    <h1>{item.ImageTitle}</h1>
                    <p>{item.Description}</p>
                  </Carousel.Caption>
                </a>
              </>
          }
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default HomeSlider

import React from "react"
import Layout from "../components/layout"
import HomePage from "../components/home/index"
import SEO from "../components/seo"
const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Home | Kentucky Tamil Sangam"
        desc="Home | Kentucky Tamil Sangam"
        keywords="Kentucky Tamil Sangam"
      />
      <HomePage />
    </Layout>
  )
}

export default IndexPage
